<template>
  <div>
    <el-form :model="formData"
      :rules="formRules"
      ref="form"
      size="mini"
      :disabled="type === 'detail'">
      <el-row :gutter="24">
        <el-col :span="12">
          <el-form-item :label="'代码'"
            prop="code">
            <el-input v-model="formData.code"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item :label="'名称'"
            prop="name">
            <el-input v-model="formData.name"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="24">
        <el-col :span="12">
          <el-form-item :label="'是否隐藏'"
            prop="isHide">
            <el-select v-model="formData.isHide"
              :placeholder="'请选择'"
              style="width: 100%">
              <el-option v-for="item in keyValues.isHide"
                :key="item.key"
                :label="item.value"
                :value="item.key"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item :label="'排序'"
            prop="rank">
            <el-input-number v-model="formData.rank"
              size="small"
              style="width:100%"
              :min="0"></el-input-number>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="24">
        <el-col :span="24">
          <el-form-item :label="'值'"
            prop="value">
            <el-input type="textarea"
              :rows="3"
              v-model="formData.value"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>
<script>
export default {
    name: 'ConfigInfoForm',
    props: {
        id: {
            type: String,
            default: ''
        },
        type: {
            type: String,
            default: 'add'
        },
        closeModal: {
            type: Function
        }
    },
    data() {
        return {
            formData: {
                name: '',
                code: '',
                isHide: '',
                rank: 0,
                value: ''
            },
            formRules: {
                name: [
                    {
                        required: true,
                        message: '请输入名称',
                        trigger: 'blur'
                    }
                ],
                code: [
                    {
                        required: true,
                        message: '请输入代码',
                        trigger: 'blur'
                    }
                ],
                isHide: [
                    {
                        required: true,
                        message: '请输入是否隐藏',
                        trigger: 'blur'
                    }
                ],
                rank: [
                    {
                        required: true,
                        message: '请选择排序',
                        trigger: 'blur'
                    }
                ],
                value: [
                    {
                        required: true,
                        message: '请输入值',
                        trigger: 'blur'
                    }
                ]
            },
            keyValues: {
                isHide: []
            }
        };
    },
    methods: {
        async bindKeyValues() {
            this.keyValues.isHide = (await this.$enum('yesorno')) || [];
        },
        async initFormValue() {
            await this.bindKeyValues();
            const {type, id} = this;
            if ((type === 'edit' || type === 'detail') && id != null) {
                this.getConfigInfo(this.id);
            }
        },
        getConfigInfo(id) {
            this.$client.getConfigInfo(id).then(data => {
                this.formData = data || {};
            });
        },
        submitHandle(cb) {
            this.$refs.form.validate(valid => {
                if (this.type === 'add') {
                    const tmp = {...this.formData};
                    delete tmp.id;
                    if (valid) {
                        this.$client.addConfigInfo(tmp).then(() => {
                            this.$message({
                                message: '操作成功！',
                                type: 'success'
                            });
                            cb();
                        });
                    }
                } else if (valid) {
                    this.$client.updateConfigInfo(this.formData).then(() => {
                        this.$message({
                            message: '操作成功！',
                            type: 'success'
                        });
                        cb();
                    });
                }
            });
        }
    },
    created() {
        this.initFormValue();
    }
};
</script>
<style lang="less" scoped>
/deep/ [label][form] {
    width: 100%;
    text-align: left;
}
</style>
